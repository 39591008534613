body, html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: rgba(255,255,255,1);
  background-attachment: fixed;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(79%, rgba(246,246,246,1)), color-stop(100%, rgba(219,219,219,1)));
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 79%, rgba(219,219,219,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 79%, rgba(219,219,219,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dbdbdb', GradientType=0 );
  background-repeat:no-repeat;
  background-attachment: fixed;
}

.page {
  margin: 0 auto;
  padding-top: 64px; 
  max-width: 600px;
  min-width: 250px;
  padding: 8px;
}

dt {
  font-weight: bold;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

#logo {
  padding-left: 32px;
  padding-top: 32px;
  width: 100px;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


#enter-form {
  margin: 0 auto;
  padding-top: 64px; 
  width: 250px;
  height: 200px;
  text-align: center;
}
