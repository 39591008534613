body, html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: rgba(255,255,255,1);
  background-attachment: fixed;
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 79%, rgba(219,219,219,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(79%, rgba(246,246,246,1)), color-stop(100%, rgba(219,219,219,1)));
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 79%, rgba(219,219,219,1) 100%);
  background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 79%, rgba(219,219,219,1) 100%);
  background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 79%, rgba(219,219,219,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 79%, rgba(219,219,219,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dbdbdb', GradientType=0 );
  background-repeat:no-repeat;
  background-attachment: fixed;
}

.page {
  margin: 0 auto;
  padding-top: 64px; 
  max-width: 600px;
  min-width: 250px;
  padding: 8px;
}

dt {
  font-weight: bold;
}
